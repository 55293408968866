import PropTypes from 'prop-types'
import React from 'react'

// Layout
import Layout from 'layout'

// Page Components
import { Section } from 'react-bulma-components'
import Hero from './components/hero'
import HowCanWeHelp from './components/how-can-we-help'
import Clients from './components/clients'
import Blogs from './components/blogs'
import ArrangeChat from './components/arrange-chat'
import About from './components/about'
import Testimonials from './components/testimonials/Testimonials'
import TeamCarousel from './components/team-carousel/TeamCarousel'

function Homepage({ data }) {
  return (
    <Layout>
      <Hero data={data} featuredMedia={data.page.featuredMedia} />
      <HowCanWeHelp data={data} />
      <Testimonials data={data} />
      <Clients data={data} />
      <TeamCarousel data={data} />
      <Blogs data={data} />
      <ArrangeChat />
      <About />
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Homepage
