/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta/Meta'
// Home Route
import HomePage from 'routes/home'

export default HomePage

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <Meta tags={data.page.seoMetaTags.tags}>
      <script type="application/ld+json" className="yoast-schema-graph">
        {`
        "@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://pocketworks.co.uk/#website","url":"https://pocketworks.co.uk/","name":"Pocketworks","description":"UK Mobile App Development Agency","potentialAction":[{"@type":"SearchAction","target":"https://pocketworks.co.uk/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://pocketworks.co.uk/#webpage","url":"https://pocketworks.co.uk/","name":"Pocketworks | UK App Developers | App Agency UK","isPartOf":{"@id":"https://pocketworks.co.uk/#website"},"datePublished":"2018-01-01T07:00:06+00:00","dateModified":"2021-01-01T07:00:06+00:00","description":"Pocketworks - UK App Developers - one of the UK\u2019s leading mobile app development agencies. Offering research-led design and a specialty in UX, we deliver over £100m in revenue for our clients. Our cross-functional team of designers and developers provides expertise in user research, design, iOS apps, Android apps, web apps and Flutter apps.","breadcrumb":{"@id":"https://pocketworks.co.uk/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://pocketworks.co.uk/"]}]},{"@type":"BreadcrumbList","@id":"https://pocketworks.co.uk/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://pocketworks.co.uk/","url":"https://pocketworks.co.uk/","name":"Home"}}]}]
      `}
      </script>
    </Meta>
  )
}

export const query = graphql`
  query IndexQuery {
    page: datoCmsPage(slug: { eq: "index" }) {
      id
      title
      slug
      excerpt
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { w: "800", fm: "jpg", auto: "compress", bg: "#F3F1ED" }
        )
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    posts: allDatoCmsArticle(
      limit: 3
      filter: { isFeatured: { eq: true }, isHiddenInListings: { ne: true } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MMMM D, YYYY")
          slug
          excerpt
          isFeatured
          featuredMedia {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                fit: "crop"
                ar: "3:2"
                fm: "jpg"
                auto: "compress"
                duotone: "f99f53,FA8072"
                duotoneAlpha: 6
              }
            )
          }
          author {
            name
            slug
            mainImage {
              gatsbyImageData(
                placeholder: TRACED_SVG
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  fit: "facearea"
                  facepad: 2.8
                  maxW: 200
                }
              )
              url
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
    caseStudies: allDatoCmsCasestudy(
      limit: 4
      sort: { fields: position, order: ASC }
      filter: { isFeatured: { eq: true }, isHidden: { eq: false } }
    ) {
      edges {
        node {
          id
          title
          slug
          heroBannerImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                h: "480"
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
          listingAppImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                fit: "crop"
                fm: "png"
                auto: "compress"
              }
            )
          }
          cardAppImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "1200"
                fit: "crop"
                fm: "png"
                auto: "compress"
              }
            )
          }
          outcomes {
            outcomeValue
            summary
          }
          client {
            companyName
            logo {
              url
              gatsbyImageData(
                placeholder: TRACED_SVG
                imgixParams: {
                  fm: "png"
                  auto: "compress"
                  maxW: 1024
                  bri: -20
                  h: "60"
                }
              )
            }
            logoLight {
              url
              gatsbyImageData(
                placeholder: TRACED_SVG
                layout: FIXED
                height: 45
                imgixParams: {
                  fm: "png"
                  auto: "compress"
                  maxH: 200
                  bri: -100
                }
              )
            }
          }
        }
      }
    }
    people: allDatoCmsAuthor(
      sort: { fields: [position], order: ASC }
      filter: { isActive: { eq: true } }
    ) {
      edges {
        node {
          id
          firstName
          lastName
          role
          slug
          mainImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                fm: "jpg"
                fit: "facearea"
                facepad: 4.0
                w: "200"
                h: "200"
              }
            )
          }
        }
      }
    }
    testimonials: allDatoCmsClientTestimonial(
      limit: 10
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          clientName
          clientRole
          content
          clientCompany
          isFlipped
          caseStudy {
            slug
          }
          clientLogo {
            alt
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "png", auto: "compress", w: "100" }
            )
          }
          clientImage {
            alt
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                w: "200"
                h: "200"
              }
            )
          }
        }
      }
    }
  }
`
