import PropTypes from 'prop-types'
import React from 'react'
import { Box, Section, Container, Columns } from 'react-bulma-components'

// COmponents
import BlogCard from 'components/blog-card'
import CaseStudyCard from 'components/case-study-card'
import Separator from 'components/separator'
import { Link } from 'gatsby'

function Blogs({ data }) {
  return (
    <Section className="is-medium">
      <Container className="content">
        <Columns className="is-centered">
          <Columns.Column className="is-8 mb-5 pt-6">
            <h3 className="is-size-6 has-text-weight-light is-outlined pt-2 mb-0">
              News &amp; Insights
            </h3>
            <h2 className="is-size-1 has-text-left title is-size-4-mobile mt-2">
              News &amp; Insights
            </h2>
          </Columns.Column>
          <Columns.Column className="is-2 mb-5 pt-6 has-text-right is-vcentered">
            <Link className="button is-dark is-rounded mt-5" to="/blog">
              See All
            </Link>
          </Columns.Column>
          <Columns.Column className="is-10-desktop is-12-tablet">
            <Columns className="is-multiline is-centered">
              {data.posts.edges.map(({ node: article }) => (
                <Columns.Column
                  className="is-4-desktop is-5-tablet"
                  key={article.slug}
                >
                  <BlogCard article={article} />
                </Columns.Column>
              ))}
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
Blogs.propTypes = {
  data: PropTypes.object,
}

export default Blogs
