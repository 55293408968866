import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Section, Container, Columns } from 'react-bulma-components'

function ArrangeChat() {
  return (
    <Section className="has-background-grey-lighter is-medium">
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-9-desktop is-10-tablet is-12-mobile has-text-centered content">
            <h2 className="title is-size-6 is-outlined pt-2 mb-0 mt-8">
              Get Started
            </h2>
            <h3 className="title is-size-1 is-size-2-mobile has-text-centered mt-3">
              Talk to us about your project
            </h3>
          </Columns.Column>
        </Columns>

        <div className="mt-3">
          <Columns className="is-inline-flex">
            <Columns.Column className="is-3 is-offset-1">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120416-jenny.jpg?fm=png&w=300&fit=facearea&facepad=4&mask=ellipse"
                alt="Jenny, who will help you get the right app"
                placeholder="tracedSVG"
              />
            </Columns.Column>
            <Columns.Column className="is-6 ">
              <br />
              <br />
              <br />
              <p className="is-size-4">
                "My name is Jenny and I'll help you understand how Pocketworks
                can create a successful app for your organisation."
              </p>
              <p className="tag is-medium">
                Jenny, Sales &amp; Business Support
              </p>
              <p className="mt-5">
                <Link
                  className="button is-primary is-leafy is-medium"
                  to="/contact"
                >
                  Arrange a chat
                </Link>
              </p>
            </Columns.Column>
          </Columns>
        </div>
      </Container>
    </Section>
  )
}

export default ArrangeChat
