import PropTypes from 'prop-types'
import React from 'react'
import { Section } from 'react-bulma-components'
import ClientQuote from '../../../../components/client-quote'

function Testimonials({ data }) {
  return (
    <Section className="is-medium has-background-grey-lighter">
      <h3 className="is-hidden title is-size-3 is-size-4-mobile has-text-centered has-text-grey-dark mb-6">
        Supporting leaders in health, transport and manufacturing.
      </h3>
      <div className="testimonials-scroll">
        {data.testimonials.edges.map(({ node: testimonial }) => (
          <div key={testimonial.id} className="testimonial-item">
            <ClientQuote testimonial={testimonial} />
          </div>
        ))}
      </div>
    </Section>
  )
}

Testimonials.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Testimonials
