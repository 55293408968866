/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Card, Section, Container, Columns } from 'react-bulma-components'
import ClientQuote from 'components/client-quote'

// Components
import Separator from 'components/separator'

function HowCanWeHelp({ data }) {
  return (
    <Section className="is-medium">
      <Container className="content is-widescreen">
        <Columns className="is-vcentered is-mobile is-centered">
          <Columns.Column className="is-5-desktop is-4-tablet pr-6 is-hidden-mobile">
            <StaticImage
              src="https://www.datocms-assets.com/20955/1579120467-speedupillustrationpocketworks.png?fm=jpg&bg=FAF7F2&w=1200"
              className="pb-0"
            />
          </Columns.Column>
          <Columns.Column className="is-7-desktop is-12-tablet is-12-mobile content ">
            <h2 className="title is-size-1-desktop is-size-2-tablet is-size-2-mobile has-text-grey-dark">
              Let's face it, there's a lot of naff apps out there. 
            </h2>
            <p className="is-size-4 mb-1">
            It's too easy to be blinded by technology or fixate on pretty design. 
            To truly make an impact, we help you:
            </p>
            
            <ul className="">
              <li>Build the right thing by getting closer to customers</li>
              <li>Make product-led growth central to your offering</li>
              <li>Deliver simpler, higher quality experiences that work</li>
            </ul>
            <p className="pt-3">Since 2012, we've applied these principles to help companies build, operate and grow apps that truly make a difference to shareholders and society.  <span className="has-text-primary is-size-4">*</span></p>
            <p className="is-size-4 mb-1 is-hidden">
            If you're under pressure to launch a successful app, it's tempting to focus on features and tech. 
            But impactful apps need more, so we help you do the things that get results. <span className="has-text-primary is-size-3">*</span>
            </p>
            <Columns className="is-hidden">
              <Columns.Column>
              <ul className="mt-0">
              <li>Customer Research</li>
              <li>Human-Centered Design</li>
              <li>Cross-Functional Teams</li>
            </ul>
              </Columns.Column>
              <Columns.Column>
              <ul className="mt-0">
              <li>Product-Led Growth</li>
              <li>Network Effects</li>
              <li>Agility</li>
            </ul>
              </Columns.Column>
            </Columns>
            
            <Link to="/services" className='is-dark button is-rounded mt-3 mb-5'>View Our Services</Link>
            
            <p className="is-hidden">
              You've got enough on your plate without blown budgets, stressful
              communications and poor product execution. We help you navigate
              the risks and create more impact with minimal time investment
              <span className="has-text-primary is-size-3">*</span>
            </p>
            <div className="has-text-grey">
              <span className="has-text-primary is-size-4">*</span>Our clients'
              apps serve over 4,000,000 people each year across health, travel and manufacturing. 85% say their apps bring the highest customer lifetime value.
            </div>
            <p className="is-size-4 is-hidden">
              We provide digital capability and leadership to help you develop
              market-leading products that improve your customers' lives using
              mobile, cloud, and AI.
            </p>
            <p className="is-size-4 is-hidden">
              We provide instant digital capability and leadership. working
              alongside you to research, develop and grow market-leading apps
              across mobile and cloud.
            </p>
            <p className="is-size-4 is-hidden">
              We're for businesses that have an ambitious vision, but need more
              software expertise to get there. We'll fill in the gaps and help
              you research, develop and grow a market-leading product using
              mobile, cloud and AI.
            </p>
          </Columns.Column>
        </Columns>
        <Columns className="mt-6 is-hidden">
          <Columns.Column className="is-5-desktop is-offset-1-desktop is-6-tablet">
            <Link to="/services/advisory/app-yes-or-no">
              <Card className="classy has-bg-blue is-equal-height">
                <span className="circle small is-top-right" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Customer Research
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3">
                  Figure out what people actually want.
                </h3>
                <p className="mt-4">
                  What are the most pressing customer pain points? How many
                  people will download your app? Is an app the right solution
                  for your business?
                </p>
                <p>
                  <Link
                    className="button is-dark"
                    to="/services/advisory/app-yes-or-no"
                  >
                    Learn more
                  </Link>
                </p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-5-desktop is-6-tablet">
            <Link to="/services/advisory/concept-design">
              <Card className="classy has-bg-green is-equal-height">
                <span className="circle small is-top-right" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Concept Design
                </h2>
                <h3 className="has-text-weight-light is-marginless pb-3 mt-2">
                  Turn ideas into concepts and prove they work.
                </h3>
                <p className="mt-4 mb-5">
                  What features do your audience care about? How should it look?
                  How can you make the most of cloud, data and AI? What's the
                  return on investment?
                </p>
                <p>
                  <Link
                    className="button is-dark"
                    to="/services/advisory/concept-design"
                  >
                    Learn more
                  </Link>
                </p>
              </Card>
            </Link>
          </Columns.Column>
        </Columns>
        <Columns className="is-hidden">
          <Columns.Column className="is-5-desktop is-offset-1-desktop is-6-tablet">
            <Link to="/services/mobile-app-development">
              <Card className="classy has-bg-orange is-equal-height">
                <span className="circle small is-bottom-left" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  App Development
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3 is-6-tablet">
                  Develop apps and digital platforms the right way.
                </h3>
                <p className="mt-4">
                  What mobile and cloud technologies are right for your
                  business? How do you build a platform that's scalable and
                  secure? What about AI and LLMs?
                </p>
                <p>
                  <Link
                    className="button is-dark"
                    to="/services/mobile-app-development"
                  >
                    Learn more
                  </Link>
                </p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-5-desktop is-6-tablet">
            <Link to="/services/advisory/growth-diagnostic">
              <Card className="classy has-bg-purple is-equal-height">
                <span className="circle small is-bottom-left" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Growth Marketing
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3 is-6-tablet">
                  Amplify your impact with data-driven growth tactics.
                </h3>
                <p className="mt-4">
                  What network effects are there? How does your retention
                  compare to benchmarks? Where should you A/B test to drive
                  revenue? How's your ASO?
                </p>
                <p>
                  <Link
                    className="button is-dark"
                    to="/services/advisory/growth-diagnostic"
                  >
                    Learn more
                  </Link>
                </p>
              </Card>
            </Link>
          </Columns.Column>
        </Columns>
        <p className="has-text-centered is-hidden">
          <Link to="/services" className="button is-dark is-rounded mt-5">
            View our services
          </Link>
        </p>
        <Columns className="mt-6">
          <Columns.Column className="is-12-desktop is-12-tablet is-hidden">
            <Link to="/services/mobile-app-development">
              <Card className="classy has-bg-white is-equal-height">
                <span className="circle small is-bottom-left" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  App Development
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3 is-6-tablet mb-3">
                  Have a capable development team at your side.
                </h3>

                <Columns>
                  <Columns.Column>
                    <h5 className="has-text-weight-light is-marginless mt-2 is-6-tablet">
                      Strategic Support
                    </h5>
                    <ul>
                      <li>Product Management</li>
                      <li>Service Design</li>
                      <li>Focus Groups &amp; Surveys</li>
                      <li>Accessibility</li>
                      <li>User Testing</li>
                    </ul>
                  </Columns.Column>
                  <Columns.Column>
                    <h5 className="has-text-weight-light is-marginless mt-2  is-6-tablet">
                      Software Delivery
                    </h5>
                    <ul>
                      <li>Native iOS &amp; Android Apps</li>
                      <li>Web Apps and Portals</li>
                      <li>API's and Integrations</li>
                      <li>Data &amp; Scalability</li>
                      <li>Software Testing</li>
                    </ul>
                  </Columns.Column>
                  <Columns.Column>
                    <h5 className="has-text-weight-light is-marginless mt-2  is-6-tablet">
                      User Growth
                    </h5>
                    <ul>
                      <li>Retention Campaigns</li>
                      <li>Conversion Optimisation</li>
                      <li>Market Research</li>
                      <li>App Store Optimisation</li>
                      <li>A/B Tests &amp; Metrics</li>
                    </ul>
                  </Columns.Column>
                </Columns>
              </Card>
            </Link>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default HowCanWeHelp
