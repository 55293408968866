import PropTypes from 'prop-types'
import React from 'react'
import { Section } from 'react-bulma-components'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

function TeamCarousel({ data }) {
  return (
    <Section className="is-medium has-background-grey-lighter">
      <div className="content has-text-centered">
        <h2 className="title is-size-6 is-outlined pt-2 mt-0 mb-0">
          Our Software Experts
        </h2>
        <h3 className="title is-size-1 is-size-2-mobile has-text-centered mt-3">
          Meet the team
        </h3>
        <p className="is-size-4">
          A group of technologists, creatives, entrepreneurs, marketers and
          strategists.
        </p>
      </div>
      <div className="team-scroll-container">
        <div className="team-scroll">
          {data.people.edges.map(({ node: person }) => (
            <div key={person.id} className="team-item">
              <Link to={`/about/pocketeers/${person.slug}`}>
                <div className="has-text-centered">
                  <GatsbyImage
                    className="is-rounded mb-3 team-member-image has-shadow"
                    image={person.mainImage.gatsbyImageData}
                    alt={`${person.role} - ${person.firstName}`}
                  />
                  <p className="has-text-left has-text-weight-semibold mb-0">
                    {person.firstName} {person.lastName}
                  </p>
                  <p className="has-text-left has-text-grey is-size-6">
                    {person.role}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

TeamCarousel.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeamCarousel
